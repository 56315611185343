<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆自编号" prop="vehicleIds">
                <a-tree-select
                  v-model="vehicleIds"
                  :replaceFields="replaceFields"
                  style="width: 100%; zindex: 100"
                  :tree-data="treeData"
                  :show-checked-strategy="SHOW_PARENT"
                  tree-checkable
                  @select="treeSelect"
                  @search="treeSearch"
                  @change="treeChange"
                  @blur="treeBlur"
                  @focus="treeFocus"
                  tree-node-filter-prop="title"
                  placeholder="请选择车辆"
                  :dropdownStyle="{ maxHeight: 500 + 'px' }"
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="汇总日期">
                <a-range-picker
                  style="width: 100%"
                  v-model="daterangeSummaryDate"
                  valueFormat="YYYY-MM-DD"
                  format="YYYY-MM-DD"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="reloadList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.summaryMonth.summaryHandleOpen()"
            v-hasPermi="['tenant:iot:summary:total']"
          >
            <a-icon type="import" />汇总统计
          </a-button>
          <a-button
            v-if="tabKey === '1'"
            v-hasPermi="['tenant:iot:vehicleDailyData:export']"
            type="primary"
            @click="handleExport()"
          >
            <a-icon type="import" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="reloadList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <summary-day ref="summaryMonth" @ok="getList" />
      <a-tabs default-active-key="1" :activeKey="tabKey" @change="tabCallback">
        <a-tab-pane key="1" tab="日数据统计">
          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            tid="1"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
          >
            <a-span slot="summaryDate" slot-scope="text, record">
              {{ parseDateTime(record.summaryDate, 'yyyy-MM-dd') }}
            </a-span>
            <a-span slot="operation" slot-scope="text, record">
              <a-divider type="vertical" />
              <a @click="$refs.createForm.handleUpdate(record, undefined)"> <a-icon type="edit" />查看 </a>
              <a-divider type="vertical" />
              <a @click="toHistoryTrack(record)"> <a-icon type="play-circle" />轨迹 </a>
            </a-span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="汇总数据统计">
          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            :columns="columnsTotal"
            :data-source="summaryList"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
          >
            <a-span slot="summaryDate" slot-scope="text, record">
              {{ record.days }}
              <!-- {{ record.summaryDate ? parseDateTime(record.summaryDate, 'yyyy-MM-dd') : addSummaryDate || '--' }} -->
            </a-span>
            <a-span slot="operation" slot-scope="text, record">
              <a-divider type="vertical" />
              <a @click="$refs.createForm.handleUpdate(record, 'summary')"> <a-icon type="edit" />查看 </a>
            </a-span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
      <!-- 数据展示 -->

      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  listVehicleDailyData,
  delVehicleDailyData,
  exportVehicleDailyData,
  listSummary
} from '@/api/iot/vehicleDailyData'
import CreateForm from './modules/CreateForm'
import { getVehicleTree } from '@/api/iot/vehicle'
import { TreeSelect, Tabs } from 'ant-design-vue'
import summaryDay from './modules/SummaryDay.vue'
import { huorsFormat } from '@/utils/util'
const SHOW_PARENT = TreeSelect.SHOW_PARENT

export default {
  name: 'VehicleDailyData',
  components: {
    CreateForm,
    ATreeSelect: TreeSelect,
    summaryDay,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane
  },
  data() {
    return {
      list: [],
      summaryList: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      addSummaryDate: '',
      // 汇总日期时间范围
      daterangeSummaryDate: [],
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      treeData: [],
      vehicleIds: undefined,
      SHOW_PARENT,
      tabKey: '1',
      // 查询参数
      queryParam: {
        vehicleId: null,
        vehicleIds: [],
        summaryDate: null,
        pageNum: 1,
        pageSize: 10
      },
      listPage: {
        pageNum: 1
      },
      listSummaryPage: {
        pageNum: 1
      },
      columns: [
        {
          title: '车辆自编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆牌号',
          dataIndex: 'licensePlateNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '汇总日期',
          dataIndex: 'summaryDate',
          scopedSlots: { customRender: 'summaryDate' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '智驾里程(km)',
          dataIndex: 'autoCtlOdom',
          ellipsis: true,
          customRender: (text, record, index) => {
            return (record.autoCtlOdom / 1000).toFixed(3)
          },
          align: 'center'
        },
        {
          title: '遥控里程(km)',
          dataIndex: 'remoteCtlOdom',
          ellipsis: true,
          customRender: (text, record, index) => {
            return (record.remoteCtlOdom / 1000).toFixed(3)
          },
          align: 'center'
        },
        {
          title: '智驾时长',
          dataIndex: 'autoCtlTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return huorsFormat(record.autoCtlTime)
          },
          align: 'center'
        },
        {
          title: '遥控时长',
          dataIndex: 'remoteCtlTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return huorsFormat(record.remoteCtlTime)
          },
          align: 'center'
        },
        {
          title: '总里程(km)',
          dataIndex: 'odo',
          ellipsis: true,
          customRender: (text, record, index) => {
            return (record.odo / 1000).toFixed(3)
          },
          align: 'center'
        },
        {
          title: '总时长',
          dataIndex: 'ctlTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return huorsFormat(record.ctlTime)
          },
          align: 'center'
        },
        {
          title: 'MPD',
          dataIndex: 'ctlTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.takeOverDay
              ? (record.autoCtlOdom / 1000 / record.takeOverDay).toFixed(2)
              : record.autoCtlOdom / 1000
          },
          align: 'center'
        },
        {
          title: '接管次数',
          dataIndex: 'takeOverDay',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'mapid',
          dataIndex: 'mapid',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      columnsTotal: [
        {
          title: '车辆自编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆牌号',
          dataIndex: 'licensePlateNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '上线日期',
          dataIndex: 'createTimeVehicle',
          ellipsis: true,
          align: 'center',
          width: '10%'
        },
        {
          title: '汇总天数',
          dataIndex: 'summaryDate',
          scopedSlots: { customRender: 'summaryDate' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '运营天数',
          dataIndex: 'operateDays',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '智驾里程(km)',
          dataIndex: 'autoCtlOdom',
          ellipsis: true,
          customRender: (text, record, index) => {
            return (record.autoCtlOdom / 1000).toFixed(3)
          },
          align: 'center'
        },
        {
          title: '遥控里程(km)',
          dataIndex: 'remoteCtlOdom',
          ellipsis: true,
          customRender: (text, record, index) => {
            return (record.remoteCtlOdom / 1000).toFixed(3)
          },
          align: 'center'
        },
        {
          title: '智驾时长',
          dataIndex: 'autoCtlTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return huorsFormat(record.autoCtlTime)
          },
          align: 'center'
        },
        {
          title: '遥控时长',
          dataIndex: 'remoteCtlTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return huorsFormat(record.remoteCtlTime)
          },
          align: 'center'
        },
        {
          title: '总里程(km)',
          dataIndex: 'odo',
          ellipsis: true,
          customRender: (text, record, index) => {
            return (record.odo / 1000).toFixed(3)
          },
          align: 'center'
        },
        {
          title: '总时长',
          dataIndex: 'ctlTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return huorsFormat(record.ctlTime)
          },
          align: 'center'
        },
        {
          title: 'MPD',
          dataIndex: 'ctlTime',
          ellipsis: true,
          customRender: (text, record, index) => {
            return record.takeOverDay
              ? (record.autoCtlOdom / 1000 / record.takeOverDay).toFixed(2)
              : record.autoCtlOdom / 1000
          },
          align: 'center'
        },
        {
          title: '接管次数',
          dataIndex: 'takeOverDay',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'mapid',
          dataIndex: 'mapid',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '8%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.initVehicleTree()
    const queryStr = sessionStorage.getItem('setParamsBeforeToHistoryTrack')
    if (queryStr) {
      const query = JSON.parse(queryStr)
      this.queryParam = query.queryParam
      this.listPage = query.listPage
      this.listSummaryPage = query.listSummaryPage
      this.vehicleIds = query.vehicleIds
      query.daterangeSummaryDate && (this.daterangeSummaryDate = query.daterangeSummaryDate)
      sessionStorage.removeItem('setParamsBeforeToHistoryTrack')
    }
    this.getList()
  },
  computed: {
    myDropdownStyle() {
      return {
        maxHeight: '500px'
      }
    }
  },
  watch: {},
  methods: {
    initVehicleTree() {
      getVehicleTree().then((res) => {
        if (res.data) {
          this.treeData = res.data
        }
      })
    },
    /** 查询车辆日数据统计列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeSummaryDate !== null &&
        this.daterangeSummaryDate !== '' &&
        this.daterangeSummaryDate.length !== 0
      ) {
        this.queryParam.params['beginSummaryDate'] = this.daterangeSummaryDate[0]
        this.queryParam.params['endSummaryDate'] = this.daterangeSummaryDate[1]
      }
      this.queryParam.pageNum = this.listPage.pageNum
      listVehicleDailyData(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = this.listTotal = response.total
        this.loading = false
      })
    },
    getListSummary() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeSummaryDate !== null &&
        this.daterangeSummaryDate !== '' &&
        this.daterangeSummaryDate.length !== 0
      ) {
        this.queryParam.params['beginSummaryDate'] = this.daterangeSummaryDate[0]
        this.queryParam.params['endSummaryDate'] = this.daterangeSummaryDate[1]
      }
      this.queryParam.pageNum = this.listSummaryPage.pageNum
      listSummary(this.queryParam).then((response) => {
        this.summaryList = response.rows
        this.addSummaryDate = this.daterangeSummaryDate[0]
          ? this.daterangeSummaryDate[0] + '~' + this.daterangeSummaryDate[1]
          : ''
        this.total = this.summaryTotal = response.total
        this.loading = false
      })
    },
    reloadList() {
      if (this.tabKey === '1') {
        this.getList()
      } else {
        this.getListSummary()
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      if (this.tabKey === '1') {
        this.listPage.pageNum = 1
        this.getList()
      } else {
        this.listSummaryPage.pageNum = 1
        this.getListSummary()
      }
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeSummaryDate = []
      this.queryParam = {
        vehicleId: undefined,
        vehicleIds: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.vehicleIds = undefined
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
      this.getListSummary()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageSize = pageSize
      if (this.tabKey === '1') {
        this.listPage.pageNum = current
        this.getList()
      } else {
        this.listSummaryPage.pageNum = current
        this.getListSummary()
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delVehicleDailyData(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicleDailyData(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    treeChange(e) {
      console.log('treeChange', e)
      this.treeChangeValue = e
      let vechileArr = []
      for (const item of this.treeChangeValue) {
        this.curFlag = false
        this.curVechileArr = []
        console.log('treeChangeValue——item', item)
        this.searchDeptAllVehicle(item)
        vechileArr = vechileArr.concat(this.curVechileArr)
      }
      this.queryParam.vehicleIds = []
      for (const item of vechileArr) {
        this.queryParam.vehicleIds.push(item.label.split('(')[0])
      }
      console.log('vechileArr', vechileArr)
    },
    treeSelect(e) {
      console.log('treeSelect', e)
    },
    treeBlur(e) {
      console.log('treeBlur', e)
    },
    treeFocus(e) {
      console.log('treeFocus', e)
    },
    searchDeptAllVehicle(id, children) {
      const data = children || this.treeData
      for (const item of data) {
        if (!id || item.id === id) {
          this.curFlag = true
          if (item.children && item.children.length > 0) {
            this.searchDeptAllVehicle(null, item.children)
          } else {
            this.curVechileArr.push(item)
          }
        } else {
          if (!this.curFlag && item.children) {
            this.searchDeptAllVehicle(id, item.children)
          }
        }
      }
    },
    treeSearch(e) {
      console.log('treeSearch', e)
    },
    tabCallback(e) {
      console.log('tabCallback', e)
      this.tabKey = e
      if (e === '1') {
        this.listTotal && (this.total = this.listTotal)
        this.queryParam.pageNum = this.listPage.pageNum
        // this.columns[3].title = '汇总日期'
        // this.columns[10].width = '18%'
      } else {
        this.queryParam.pageNum = this.listSummaryPage.pageNum
        // this.columns[3].title = '汇总天数'
        // this.columns[10].width = '10%'
        if (this.summaryList.length === 0) {
          this.getListSummary()
        } else {
          this.summaryTotal && (this.total = this.summaryTotal)
        }
      }
      this.$forceUpdate()
    },
    toHistoryTrack(record) {
      const query = {
        queryParam: this.queryParam,
        listPage: this.listPage,
        listSummaryPage: this.listSummaryPage,
        vehicleIds: this.vehicleIds,
        daterangeSummaryDate: this.daterangeSummaryDate
      }
      sessionStorage.setItem('setParamsBeforeToHistoryTrack', JSON.stringify(query))
      this.$nextTick(() => {
        this.$router.push({
          path: '/monitor/vehicle',
          query: {
            date: record.summaryDate,
            vehicleId: record.vehicleId,
            type: 'track'
          }
        })
      })
    }
  }
}
</script>
